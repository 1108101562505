<template>
  <a-tabs
    :activeKey="activeKey.key"
    type="editable-card"
    @tabClick="onTabClick"
    @edit="onEdit"
    class="content-body"
    hide-add
    size="small"
  >
    <a-tab-pane
      v-for="item in tabs"
      :key="item.key"
      :tab="item.title"
      :closable="item.closable"
    >
      <router-view v-slot="{ Component }">
        <!-- vue3.0配置 keep-alive缓存-->
        <keep-alive>
          <component
            :is="Component"
            :key="$route.name"
            v-if="$route.meta.keepAlive"
          />
        </keep-alive>
        <component
          :is="Component"
          :key="$route.name"
          v-if="!$route.meta.keepAlive"
        />
      </router-view>
    </a-tab-pane>
  </a-tabs>
</template>
<script>
import { defineComponent, reactive, toRefs } from "vue";
import router from "@/router/index";
import store from "@/store";
export default defineComponent({
  name: "Content",
  // 接受父组件传递的值
  props: {
    tabs: {
      type: Object,
      default: () => {},
    },
    activeKey: {
      type: Object,
      default: () => {},
    },
  },
  emits: [
    "handleSetActiveKey",
    "handleSetSelectedKeys",
    "handleSetOpenKeys",
    "handleSetTabs",
  ],
  setup(props, context) {
    const state = reactive({});
    const onTabClick = (e) => {
      let v_openKeys = [props.tabs.find((f) => f.key === e).parent];
      sessionStorage.setItem("selectedKeys", "/" + e);
      sessionStorage.setItem("activeKey", JSON.stringify({ key: e }));
      sessionStorage.setItem("openKeys", v_openKeys);
      context.emit("handleSetOpenKeys", v_openKeys);
      context.emit(
        "handleSetActiveKey",
        JSON.parse(sessionStorage.getItem("activeKey"))
      );
      context.emit("handleSetSelectedKeys", [
        sessionStorage.getItem("selectedKeys"),
      ]);
      router.push("/" + e);
    };
    const onEdit = (targetKey) => {
      store.dispatch("closeTab", targetKey);
      console.log(JSON.parse(sessionStorage.getItem("tabs")));
      context.emit("handleSetTabs", JSON.parse(sessionStorage.getItem("tabs")));
      context.emit(
        "handleSetActiveKey",
        JSON.parse(sessionStorage.getItem("activeKey"))
      );
      context.emit("handleSetOpenKeys", [sessionStorage.getItem("openKeys")]);
      context.emit("handleSetSelectedKeys", [
        sessionStorage.getItem("selectedKeys"),
      ]);
    };
    return {
      ...toRefs(state),
      onTabClick,
      onEdit,
    };
  },
});
</script>
<style lang="scss">
// .ant-tabs {
//   background: bisque;
// }
// .ant-tabs-card-bar {
//   background: brown;
// }
.ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab {
  background: rgb(220, 248, 248);
  border-radius: 10px;
}
.ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab-active {
  background: rgb(176, 235, 250);
}
</style>